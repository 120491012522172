import React from 'react'
import Link from 'gatsby-link'
import ScanningContainer from '../../components/ScanningContainer'
import { Header ,Icon } from 'semantic-ui-react'

import './style.css'

class ScanningHitIndex extends React.Component {
    state = {
        // isActive: 1
    }

    componentDidMount() {
        // this.getScans();
    }


    render () {
        let indexClass;
        const scanningHitLi = this.props.allHits.filter(record => record.fields.number).map((record) => {
            if (this.props.isActive == record.fields.number) {
            indexClass = "active-signal-index"
            } else {indexClass = ""}
            return (
            <li className={record.fields.color + " " + indexClass} onClick={this.props.onClick.bind(this, record.fields.number) } key={record.fields.number}>{record.fields.name}</li>
            )}
        );

        return (
              <ul className="scanning-hits-index-wrapper">
                  {scanningHitLi}
              </ul>
        )
    }
}


export default ScanningHitIndex
