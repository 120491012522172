import React from 'react'
import Link from 'gatsby-link'
import ScanningContainer from '../../components/ScanningContainer'
import ScanningHitsIndex from '../../components/ScanningHitIndex'
import { Header ,Icon, Divider } from 'semantic-ui-react'

import './style.css'

class ScanningHits extends React.Component {
    state = {
        isActive: 1
    }

    componentDidMount() {
        this.getScans();
    }

    getScans() {
        fetch("https://api.airtable.com/v0/app1POYiufgjnmVCa/Scanning%20Hits?maxRecords=100&view=Signal%20Types", {
         headers: {"Authorization": "Bearer keysWOUXtGyYz8SY2"}
       }).then((response) => response.json())
           .then((responseData) => {
               console.log('SCANS', responseData)
               const allHits = responseData.records.filter(record => record.fields.number)
               console.log('FIlters SCANS', allHits);
               this.setState({allHits}, () => {
                   console.log(this.state.allHits);
               })
         })
    }

    handleIndexClick(i) {
        console.log(i)
        this.setState({
            isActive: i
        })
        console.log(this.state.isActive)
    }

    handleNext() {
        let isActive = this.state.isActive
        const signalLength = this.state.allHits.length - 1
        if (isActive > signalLength) {
            this.setState({
                isActive: 1
            })
        } else {
        this.setState({
            isActive: isActive + 1
        })
        }
        console.log(this.state.isActive)
    }

    handleBack() {
        let isActive = this.state.isActive
        const signalLength = this.state.allHits.length
        console.log(this.state.isActive)
        if (isActive <= 1) {
            this.setState({
                isActive: signalLength
            })
        } else {
        this.setState({
            isActive: isActive - 1
        }, () =>{
            console.log(this.state.isActive)
        })
        }
    }
    render () {
        const allHits = this.state.allHits;
        return (
            <div className="scanning-hit-page">
                <div className="section-content">
                    <div className="article-back ab-scanning-hits"><Link to='/research'>&larr; Research </Link></div>
                    {/* <h1 className="scanning-header">Scanning Hits</h1> */}
                    <div className="back-large" onClick={this.handleBack.bind(this)}>
                        Prev
                    </div>
                    <div className="next-large" onClick={this.handleNext.bind(this)}>
                        Next
                    </div>
                    {allHits ?
                    <div>
                        {/* <ScanningHitsIndex isActive={this.state.isActive} allHits={this.state.allHits} onClick={this.handleIndexClick.bind(this)}/> */}
                        <div className="arrow-container">
                            <div className="back-arrow" onClick={this.handleBack.bind(this)}>
                                {/* <Icon name="arrow left" size="large"/> */}
                                Prev
                            </div>
                            <div className="next-arrow" onClick={this.handleNext.bind(this)}>
                                {/* <Icon name="arrow right" size="large"/> */}
                                Next
                            </div>
                        </div>
                        <ScanningContainer isActive={this.state.isActive} allHits={this.state.allHits}/>
                    </div>: null }
                </div>
            </div>
        )
    }
}


export default ScanningHits
